import React, { useState } from 'react';
import { Input, Button, VStack, Heading, Text, Link, InputGroup, InputLeftElement, Box, Alert, AlertIcon, useToast, Flex } from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import getUserWorkspaces from './utils/getUserWorkspaces';
import logo from '../assets/ayoicon.png';
import { FcGoogle } from 'react-icons/fc';
import { getFirestore, doc, setDoc, serverTimestamp, collection, addDoc, getDoc } from 'firebase/firestore';
// import RightSideContent from './widgets/RightSideContent';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMode, setResetMode] = useState(false);
  const navigate = useNavigate();
  const db = getFirestore();
  const toast = useToast();

  const createUserDocument = async (user) => {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      const workspaceRef = await addDoc(collection(db, 'workspaces'), {
        name: "My Workspace",
        totalMinutes: 15,
        status: 'free',
        planPriceIds: ["Free"],
        createdAt: serverTimestamp(),
        ownerId: user.uid,
        members: [
          {
            userId: user.uid,
            email: user.email,
            name: user.displayName,
            createdAt: new Date(),
            status: 'accepted',
            role: 'owner',
          },
        ],
      });

      await setDoc(userDocRef, {
        email: user.email,
        uid: user.uid,
        name: user.displayName,
        createdAt: serverTimestamp(),
        workspaces: [workspaceRef.id],
      });

      return workspaceRef.id;
    } else {
      const userData = userDoc.data();
      return userData.workspaces[0];
    }
  };

  const handleLogin = async () => {
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      const workspaces = await getUserWorkspaces(user.uid);
      
      if (workspaces.length > 0) {
        navigate(`/dashboard/${workspaces[0]}`);
      } else {
        setError("No workspaces found for user.");
        console.error("No workspaces found for user.");
      }
    } catch (error) {
      setError(error.message);
      console.error("Error logging in:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    setError('');
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const workspaceId = await createUserDocument(result.user);
      navigate(`/dashboard/${workspaceId}`);
    } catch (error) {
      setError(error.message);
      console.error("Error signing in with Google:", error);
    }
  };

  const handlePasswordReset = async () => {
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: "Password reset email sent",
        description: "You will receive an email to reset your password if your email exists.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setResetMode(false);
      setEmail('');
    } catch (error) {
      setError(error.message);
      console.error("Error resetting password:", error);
    }
  };

  return (
    <Flex minH="100vh" flexDirection={{ base: 'column', lg: 'row' }}>
      <Flex flex={{ base: '1', lg: '2' }} justify="center" align="center" p={8}>
        <VStack spacing={4} w="full" maxW="md">
        <Box borderRadius="10px" overflow="hidden" boxSize="50px" cursor="pointer" onClick={() => window.location.href = 'https://clientbaseai.com'}>
            <img src={logo} alt="Clientbase Logo" style={{ width: 'full', height: 'auto' }} />
          </Box>
          <Heading>{resetMode ? 'Reset Password' : 'Login'}</Heading>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          {!resetMode && (
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <LockIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
          )}
          {!resetMode ? (
            <>
              <Button colorScheme="blue" size="lg" w="full" onClick={handleLogin}>Login</Button>
              <Text>
                Forgot your password? <Link onClick={() => setResetMode(true)} cursor="pointer" color="blue.500">Click here to reset it</Link>
              </Text>
            </>
          ) : (
            <>
              <Button colorScheme="blue" size="lg" w="full" onClick={handlePasswordReset}>Send Reset Email</Button>
              <Text onClick={() => setResetMode(false)} cursor="pointer" color="blue.500">Go back</Text>
            </>
          )}
          {!resetMode && (
            <Button leftIcon={<FcGoogle />} colorScheme="gray" size="lg" w="full" onClick={handleGoogleSignIn}>
              Continue with Google
            </Button>
          )}
          {!resetMode && (
            <Text>
              Don't have an account? <Link color="blue.500" onClick={() => navigate('/signup')}>Click here to sign up</Link>
            </Text>
          )}
        </VStack>
      </Flex>
      <Box flex={{ base: '1', lg: '3' }} display={{ base: 'none', lg: 'block' }} bg="#222222">
        {/* <RightSideContent />  */}
      </Box>
    </Flex>
  );
};

export default Login;
