import React, { useState, useEffect } from 'react';
import { VStack, Image, Button, Box, Icon, Text, useToast } from '@chakra-ui/react';
import { useParams, Outlet, Link, Navigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from './AuthContext';
import WorkspaceDropdown from './widgets/WorkspaceDropdown';
import SearchBar from './widgets/SearchBar';
import UsageAndUpgrade from './widgets/UsageAndUpgrade';
import logo from '../assets/ayologo.png';
import logoIcon from '../assets/ayoicon.png';
import { FiGrid, FiPhone, FiMessageSquare, FiUser, FiCloud, FiSettings, FiFolder, FiChevronLeft, FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { db } from '../Firebase';
import { collection, getDocs } from 'firebase/firestore';

const MotionBox = motion(Box);

const Dashboard = () => {
  const { currentUser } = useAuth();
  const { workspaceId } = useParams();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [voiceAgents, setVoiceAgents] = useState([]);
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    const fetchVoiceAgents = async () => {
      try {
        console.log(`Fetching voice agents for workspace: ${workspaceId}`);
        const agentsCollectionRef = collection(db, `workspaces/${workspaceId}/agents`);
        const querySnapshot = await getDocs(agentsCollectionRef);
        
        const agents = [];
        querySnapshot.forEach((doc) => {
          const agentData = doc.data();
          console.log(`Found agent: ${agentData.name}`);
          agents.push({
            id: doc.id,
            ...agentData
          });
        });
        
        console.log(`Total agents found: ${agents.length}`);
        setVoiceAgents(agents);
      } catch (error) {
        console.error("Error fetching voice agents:", error);
        toast({
          title: "Error",
          description: "Failed to fetch voice agents. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchVoiceAgents();
  }, [workspaceId, toast]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const menuItems = [
    { icon: FiGrid, label: "Overview", path: "overview" },
    { icon: FiPhone, label: "Voice Agents", path: "voice-agents", hasDropdown: voiceAgents.length > 0 },
    { icon: FiFolder, label: "Call Logs", path: "call-logs" },
    { icon: FiMessageSquare, label: "Messages", path: "messages" },
    { icon: FiUser, label: "Contacts", path: "contacts" },
    { icon: FiCloud, label: "Integrations", path: "integrations" },
    { icon: FiSettings, label: "Settings", path: "settings" },
  ];

  const isVoiceAgentsActive = location.pathname.includes('/voice-agent');

  return (
    <Box display="flex" minHeight="100vh">
      <VStack
        as="nav"
        w={isCollapsed ? "80px" : "280px"}
        minHeight="100vh"
        align="start"
        position="sticky"
        top="0"
        borderRightWidth="1px"
        borderColor="gray.200"
        transition="width 0.3s ease"
        bg="white"
        boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      >
        <Box width="100%" textAlign="center" display="flex" justifyContent="center" alignItems="center" p={4} borderBottomWidth="1px" borderColor="gray.200">
          <Image src={isCollapsed ? logoIcon : logo} alt="Clientbase Logo" height="48px" borderRadius={10}       objectFit="contain"  />
        </Box>
        <VStack w="100%" p={4} spacing={isCollapsed ? 2 : 2} align="start" flex="1">
          <WorkspaceDropdown zIndex={1000} />
          {menuItems.map((item, index) => (
            <Box key={index} w="100%">
              <Button
                as={Link}
                to={`/dashboard/${workspaceId}/${item.path}`}
                variant="ghost"
                w="100%"
                justifyContent={isCollapsed ? "center" : "flex-start"}
                leftIcon={<Icon as={item.icon} />}
                rightIcon={!isCollapsed && item.hasDropdown ? <Icon as={FiChevronDown} transform={isVoiceAgentsActive ? "rotate(180deg)" : "rotate(0deg)"} transition="transform 0.3s ease" /> : null}
                bg={isVoiceAgentsActive && item.hasDropdown ? "gray.100" : "transparent"}
                _hover={{ bg: "gray.100" }}
                transition="all 0.3s ease"
              >
                {!isCollapsed && <Text>{item.label}</Text>}
              </Button>
              <AnimatePresence>
                {!isCollapsed && item.hasDropdown && isVoiceAgentsActive && (
                  <MotionBox
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    overflow="hidden"
                  >
                    <VStack align="start" ml={5} mt={2} spacing={2}>
                      {voiceAgents.map((agent) => (
                        <Button
                          key={agent.id}
                          as={Link}
                          to={`/dashboard/${workspaceId}/voice-agent/agents/${agent.id}`}
                          variant="ghost"
                          size="sm"
                          w="100%"
                          justifyContent="flex-start"
                          _hover={{ bg: "gray.100" }}
                          transition="all 0.2s ease"
                        >
                          {agent.name}
                        </Button>
                      ))}
                    </VStack>
                  </MotionBox>
                )}
              </AnimatePresence>
            </Box>
          ))}
          <Button
            onClick={() => setIsCollapsed(!isCollapsed)}
            variant="ghost"
            w="100%"
            justifyContent={isCollapsed ? "center" : "flex-start"}
            leftIcon={<Icon as={isCollapsed ? FiChevronRight : FiChevronLeft} />}
            display="flex"
            alignItems="center"
            _hover={{ bg: "gray.100" }}
            transition="all 0.3s ease"
          >
            {!isCollapsed && "Collapse"}
          </Button>
        </VStack>
        {!isCollapsed && (
          <Box w="100%" p={4} mt="auto" borderTopWidth="1px" borderColor="gray.200" display={{ base: 'none', md: 'block' }}>
            <UsageAndUpgrade />
          </Box>
        )}
      </VStack>
      <Box flex="1">
        <SearchBar />
        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;